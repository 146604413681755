import type { User } from 'lucia';
import { getContext, setContext } from 'svelte';
import { writable, type Writable } from 'svelte/store';

export const USER_CONTEXT = 'USER_CONTEXT';

export type UserContext = Writable<User | undefined>;

export function setUser(user: User | undefined) {
	const userState = setContext<UserContext>(USER_CONTEXT, writable(user));
	return userState;
}

export function getUser() {
	return getContext<UserContext>(USER_CONTEXT);
}
